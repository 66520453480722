@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    @apply appearance-none;
    margin: 0;
  }

  .no-spinner {
    @apply appearance-none;
  }
}


/* * {
  box-sizing: border-box;
}
:root{
  overflow: hidden;
} */

body::-webkit-scrollbar {
  display: none;
}

.link {
  text-decoration: none;
  list-style-type: none;
}
.max-width {
  max-width: 1280px;
  width: 100%;
  // padding: 0 1.2rem;
  margin-inline: auto;
  @media screen and (max-width: 768px) {
    max-width: 720px;
    margin-inline: auto;
    width: 100%;
    padding: 0 1.2rem;
  }
  @media screen and (max-width: 512px) {
    width: 100%;
    max-width: 100%;
    padding: 0 1.2rem;
  }
}
.ourwork-pagination {
  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    opacity: 1;
    border: #fd5c01 1.5px solid;
    background: transparent;
  }
  .swiper-pagination-bullet-active {
    background: #fd5c01;
  }
}
