.banner {
    background-image: url("../assets/Ellipse.png");
    background-repeat: no-repeat;
    background-size: calc(100% + 20px) calc(100%);
}

@media screen and (max-width:768px) {
    .banner {
        background-size: calc(100% + 400px) calc(100%);
    }
}