@media screen and (max-width: 768px) {
    .testimonial {
        overflow: hidden;
    }



    .testimonial .parent {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .testimonial .child {
        display: inline-block;
    }

    .testimonial .parent::-webkit-scrollbar {
        display: none;
    }
}